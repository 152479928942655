const header = document.getElementById('header');
const fixedClass = 'fixed';
const activeClass = 'visible';
const activeTiming = 1340;

// header にクラスを付け替える
const headerClass = (e, h) => {
  if ( window.scrollY > h ) {
    header.classList.add(e);
  } else {
    header.classList.remove(e);
  }
}


document.addEventListener('DOMContentLoaded', function(){
  // header を fixed にする
  headerClass(fixedClass, header.clientHeight);
  // header の表示
  headerClass(activeClass, activeTiming);
  window.addEventListener('scroll', function (e) {
    headerClass(fixedClass, header.clientHeight);
    headerClass(activeClass, activeTiming);
  });
});
